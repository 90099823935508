import React, { Component } from "react";
import { Card, Typography, Table, Pagination, Select, Skeleton,Button } from "antd";
import axios from "axios";
import CardMaterial from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";

import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { ANALYTICS_API, USER_PROFILE,CURRENT_USER_SUB_ORG_LIST } from "../../api/constants";

import "../login.css";

const { Text, Title } = Typography;
const { Column } = Table;
const { Option } = Select;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Tooltip,
  Legend
);

class Attendance extends Component {
  state = {
    start_time: moment().startOf("day").format("x"),
    start_time_view: moment().startOf("day"),
    absent_users: 0,
    on_leave_users: 0,
    present_users: 0,
    total_users: 0,
    late_entry: 0,
    early_exit: 0,
    attendance_data: [],
    user_org_id: "",
    page: 1,
    page_size: 10,
    total: 0,
    attendance_percentage_graph_data: [],
    loading: false,
    current_user_sub_orgs: [],
    current_user_sub_org_id: "",
    sub_org_id_attendance_search: "",
  };
  componentDidMount() {
    this.getCurrentUserSubOrgs();
  }

  pageChange = (current, pageSize) => {
    this.setState({ page: current }, () => {
      console.log("page number", this.state.page);
      this.getAttendanceTableData();
    });
  };

  setLimit = (value) => {
    this.setState({ page_size: value }, () => {
      console.log(this.state.page_size);
      this.getAttendanceTableData();
    });
  };

  getCurrentUserSubOrgs = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CURRENT_USER_SUB_ORG_LIST}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from current user sub org list", response);

          this.setState(
            {
              current_user_sub_orgs: response.data.data,
              current_user_sub_org_id:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
                  sub_org_id_attendance_search:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
            },
            () => {
              this.getUserdata();
            }
          );
        })
        .catch((error) => {
          console.log("error from current user sub org list api", error);
        });
    });
  };

  setCurrentUserSubOrg = (value) => {
    this.setState(
      { current_user_sub_org_id: value, sub_org_id_attendance_search: value },
      () => {
        this.getUserdata();
      }
    );
  };

  getUserdata = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${USER_PROFILE}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from user profile", response);

          this.setState(
            {
              user_org_id: response.data.organization.$oid,
            },
            () => {
              this.getAttendanceData();
              this.getAttendancePercentageData();
              this.getAttendanceTableData();
            }
          );
        })
        .catch((error) => {
          console.log("error from user profile", error);
        });
    });
  };

  getAttendanceData = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}user_attendance?sub_organization=${this.state.sub_org_id_attendance_search}&organization_id=${this.state.user_org_id}&selected_date=${this.state.start_time}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from attendance chart api", response);
          this.setState({
            absent_users: response.data.absent_users ?? 0,
            on_leave_users: response.data.on_leave_users ?? 0,
            present_users: response.data.present_users ?? 0,
            total_users: response.data.total_users ?? 0,
            late_entry: response.data.late_entry ?? 0,
            early_exit: response.data.early_exit ?? 0,
            // attendance_data: response.data.user_status.length>20 ?response.data.user_status.slice(0, 20):response.data.user_status,
          });
        })
        .catch((error) => {
          console.log("error from attendance chart api", error);
        });
    });
  };
  getAttendancePercentageData = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}monthly_attendance_present_percentage_monthly?organization_id=${this.state.user_org_id}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from attendance percentage api", response);
          this.setState({
            attendance_percentage_graph_data: response.data?.graph_data ?? [],
          });
        })
        .catch((error) => {
          console.log("error from attendance percentage api", error);
        });
    });
  };
  getAttendanceTableData = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}user_attendance_table?sub_organization=${this.state.sub_org_id_attendance_search}&page_size=${this.state.page_size}&page=${this.state.page}&organization_id=${this.state.user_org_id}&selected_date=${this.state.start_time}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log("response from attendance table api", response);
          this.setState({
            attendance_data: response.data.user_status,
            total: response.data.total_count,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from attendance table api", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return (
        <Skeleton
          paragraph={{
            rows: 20,
          }}
          active
        />
      );
    }
    return (
      <div style={{ marginTop: 10 }}>
        <Card>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <DatePicker
                  
                  // minDate={moment().subtract(30, "days")}
                  disableFuture
                  inputVariant="outlined"
                  style={{marginTop:15}}
                  inputProps={{
                      style: {
                        padding: 10,
                        height: 14,
                        width:130
                      }
                    }}
                  
                  value={this.state.start_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        start_time: moment(value).startOf("day").format("x"),
                        start_time_view: value,
                      },
                      () => {
                        this.getAttendanceData();
                        this.getAttendanceTableData();
                      }
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text> User Sub Organization : {""}</Text>
              <Select
                style={{ minWidth: 200 }}
                disabled={
                  this.state.current_user_sub_orgs.length < 1 ? true : false
                }
                value={this.state.current_user_sub_org_id}
                onChange={this.setCurrentUserSubOrg}
              >
                {this.state.current_user_sub_orgs.map((r) => {
                  return <Option key={r.id}>{`${r.name}`}</Option>;
                })}
              </Select>
            </div>
            {this.props.userProfileData.data_level.access_all_data ? (
              <Button
                type="link"
                style={{ marginTop: 20, color: "#1A3337" }}
                onClick={() => {
                  this.setState(
                    { current_user_sub_org_id: "", sub_org_id_attendance_search: "" },
                    () => {
                      this.getUserdata();
                    }
                  );
                }}
              >
                All Sub Orgs
              </Button>
            ) : null}
          </div>
        </Card>
        <Card style={{ marginTop: 5 }}>
          <div
            className="cardscontainer"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,

                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    Total
                  </Text>
                  <Title
                    style={{ marginTop: 5, color: "#191919" }}
                  >{`${this.state.total_users}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>

            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,

                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    Present
                  </Text>
                  <Title
                    style={{ marginTop: 5, color: "#191919" }}
                  >{`${this.state.present_users}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>

            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,

                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    On Leave
                  </Text>
                  <Title
                    style={{ marginTop: 5, color: "#191919" }}
                  >{`${this.state.on_leave_users}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>
            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,

                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    Absent
                  </Text>
                  <Title
                    style={{ marginTop: 5, color: "#191919" }}
                  >{`${this.state.absent_users}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>
            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,

                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    Late Entry
                  </Text>
                  <Title
                    style={{ marginTop: 5, color: "#191919" }}
                  >{`${this.state.late_entry}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>
            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,

                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    Early Exit
                  </Text>
                  <Title
                    style={{ marginTop: 5, color: "#191919" }}
                  >{`${this.state.early_exit}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>
          </div>
        </Card>
        {/* badges end here */}
        <Bar
          style={{ marginBottom: 20 }}
          data={{
            labels: this.state.attendance_percentage_graph_data.map(
              (r) => r.month_year
            ),
            datasets: [
              {
                data: this.state.attendance_percentage_graph_data.map(
                  (r) => r.attendance_percentage
                ),
                backgroundColor: "#0A6847",
                label: 'Attendance Percentage by Month',
              },
            ],
          }}
          options={{
            responsive: true,
            aspectRatio: 4,
          }}
        />
        <Card style={{ marginTop: 5, width: "100%" }}>
          <Title style={{ marginLeft: 15 }} level={3}>
            Recent Attendances
          </Title>
          <div style={{ margin: 10 }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.page_size} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            <Text>{`  Entries `}</Text>
          </div>
          <Table
            dataSource={this.state.attendance_data}
            pagination={false}
            rowKey={(record) => record._id}
            size="small"
          >
            <Column
              title="Name"
              key="name"
              render={(text, record) => <span>{`${record?.name ?? ""}`}</span>}
            />
            <Column
              title="Mobile"
              key="mobile"
              render={(text, record) => (
                <span>{`${record?.mobile ?? ""}`}</span>
              )}
            />
            <Column
              title="Entry Status"
              key="entry_status"
              render={(text, record) => (
                <span
                  style={{
                    color: record.entry_status === "Present" ? "green" : "red",
                  }}
                >{`${record?.entry_status ?? ""}`}</span>
              )}
            />
            <Column
              title="Exit Status"
              key="exit_status"
              render={(text, record) => (
                <span
                  style={{
                    color: record.exit_status === "Present" ? "green" : "red",
                  }}
                >{`${record?.exit_status ?? ""}`}</span>
              )}
            />
            <Column
              title="Status"
              key="status"
              render={(text, record) => (
                <span
                  style={{
                    color: record.status === "Present" ? "green" : "red",
                  }}
                >{`${record?.status ?? ""}`}</span>
              )}
            />

            {/* <Column
              title="Created On"
              key="created_on"
              render={(text, record) => (
                <span>{`${record?.created_on ?? ""}`}</span>
              )}
            /> */}
          </Table>
          {this.state.total > 10 ? (
            <Pagination
              pageSize={Number(this.state.page_size)}
              current={this.state.page}
              total={this.state.total}
              onChange={this.pageChange}
              style={{ marginTop: 10 }}
            />
          ) : null}
        </Card>
        {/**Tables end here */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, {
  getUserData,
})(Attendance);

import React, { Component } from "react";
import {
  Card,
  Button,
  Select,
  Typography,
  Table,
  Divider,
  Input,
  Pagination,
  Icon,
  Modal,
} from "antd";
import { connect } from "react-redux";
import { saveOrderListParams } from "../../redux/actions/orderListParamsAction";
import { getUserData } from "../../redux/actions/userProfileAction";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import FileSaver from "file-saver";

import {
  EDIT_ORDER,
  ADVANCED_ORDER_SEARCH,
  ORDER_EXTENDED_REPORT,
  ORDER_DETAILED_INVOICE,
  ORDER_INVOICE_PDF,
  CURRENT_USER_SUB_ORG_LIST,
  GET_FIRST_CHILD_SUB_ORG_LIST,
  ORDER_EXTENDED_SUMMARY_PDF,
  ORDER_EXTENDED_PRODUCT_SUMMARY,
  ORDER_SUMMARY_REPORT,
  ORDER_INVOICE_POS,
  // ORDER_EXTENDED_SUMMARY_EXCEL,
  ORDER_EXTENDED_MULTISHEET_EXCEL,
  RETURN_ORDER_EXTENDED_MULTISHEET_EXCEL,
  ORDER_TYPE_CHOICES,
  // ORDER_FINANCE_REPORT,
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  GET_USER_LOCAL,
} from "../../utilities/utilities";
import ReactGA from "react-ga";

import "../login.css";
import { debounce } from "lodash";

const { Option } = Select;
const { Text, Title } = Typography;
const { Column } = Table;

class AdvancedOrderList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    limit: 10,
    loading: false,
    currentTime: "",
    timezone_name: "",
    order_number: "",
    name: "",
    details: "",
    drop_contact_no: "",
    drop_contact_name: "",
    start_loc_name: "",
    end_loc_name: "",
    bill_amount: "",
    received_amount: "",
    due_amount: "",
    start_max_distance: "",
    end_max_distance: "",
    start_time: moment().startOf("day").format("x"),
    start_time_view: moment().startOf("day"),
    end_time: Date.now(),
    end_time_view: moment(),
    extended_report_from: Date.now() / 1000,
    extended_report_from_view: moment(),
    extended_report_to: Date.now() / 1000,
    extended_report_to_view: moment(),
    // order_finance_report_from: moment().startOf("month").startOf("day").format("x"),
    // order_finance_report_from_view: moment().startOf("month").startOf("day"),
    // order_finance_report_to: moment().endOf("day").format("x"),
    // order_finance_report_to_view: moment().endOf("day"),
    summary_type: "order",
    delivered: "",
    cancelled: "",
    rescheduled: "",
    search_date_time_type: "created_on",
    datarange: "",
    sort_by: "created_on",
    sort_type: "dsc",
    created_by_name: "",
    created_on: null,
    modified_by_name: "",
    modified_on: null,
    refundable_amount: "",
    total_received_amount: "",
    total_refunded_amount: "",
    order_ids: [],
    current_user_sub_orgs: [],
    current_user_sub_org_id: "",
    first_child_sub_orgs: [],
    first_child_sub_org_id: "",
    sub_org_id_order_search: "",
    show_excel_loading_button: false,
    show_pdf_loading_button: false,
    sub_org_name: "",
    inventory_entity_name: "",
    sub_org_parent_name: "",
    order_status: "",
    see_more_details: false,
    details_text: "",
    order_type: "",
    order_types: [],
  };
  componentDidMount() {
    this.props.getUserData();
    this.getOrderTypes();
    ReactGA.pageview(window.location.pathname);

    console.log("redux", this.props.orderListParams);
    if (Object.keys(this.props.orderListParams).length !== 0) {
      console.log("inside if");
      this.setState(
        {
          order_number: this.props?.orderListParams?.order_number ?? "",
          order_type: this.props?.orderListParams?.order_type ?? "",
          name: this.props?.orderListParams?.name ?? "",
          inventory_entity_name:
            this.props?.orderListParams?.inventory_entity_name ?? "",
          sub_org_parent_name:
            this.props?.orderListParams?.sub_org_parent_name ?? "",
          order_status: this.props?.orderListParams?.order_status ?? "",
          sub_org_name: this.props?.orderListParams?.sub_org_name ?? "",
          created_by_name: this.props?.orderListParams?.created_by_name ?? "",
          created_on: this.props?.orderListParams?.created_on?.$date ?? null,
          modified_by_name: this.props?.orderListParams?.modified_by_name ?? "",
          modified_on: this.props?.orderListParams?.modified_on?.$date ?? null,
          details: this.props?.orderListParams?.details ?? "",
          drop_contact_no: this.props?.orderListParams?.drop_contact_no ?? "",
          drop_contact_name:
            this.props?.orderListParams?.drop_contact_name ?? "",
          start_loc_name: this.props?.orderListParams?.start_loc_name ?? "",
          end_loc_name: this.props?.orderListParams?.end_loc_name ?? "",
          bill_amount: this.props?.orderListParams?.bill_amount ?? "",
          received_amount: this.props?.orderListParams?.received_amount ?? "",
          due_amount: this.props?.orderListParams?.due_amount ?? "",
          refundable_amount:
            this.props?.orderListParams?.refundable_amount ?? "",
          total_received_amount:
            this.props?.orderListParams?.total_received_amount ?? "",
          total_refunded_amount:
            this.props?.orderListParams?.total_refunded_amount ?? "",
          start_max_distance:
            this.props?.orderListParams?.start_max_distance ?? "",
          end_max_distance: this.props?.orderListParams?.end_max_distance ?? "",
          start_time:
            this.props?.orderListParams?.start_time ??
            moment().startOf("day").format("x"),
          start_time_view:
            this.props?.orderListParams?.start_time_view ??
            moment().startOf("day"),
          end_time: this.props?.orderListParams?.end_time ?? Date.now(),
          end_time_view: this.props?.orderListParams?.end_time_view ?? moment(),
          delivered: this.props?.orderListParams?.delivered ?? "",
          cancelled: this.props?.orderListParams?.cancelled ?? "",
          rescheduled: this.props?.orderListParams?.rescheduled ?? "",
          search_date_time_type:
            this.props?.orderListParams?.search_date_time_type ??
            "delivery_date_time",
          limit: this.props?.orderListParams?.limit ?? 10,
          page: this.props?.orderListParams?.page ?? 0,
          sort_by: this.props?.orderListParams?.sort_by ?? "created_by",
          sort_type: this.props?.orderListParams?.sort_type ?? "dsc",
        },
        () => {
          // this.getOrderList();
          this.getCurrentUserSubOrgs();
        }
      );
    } else {
      // this.getOrderList();
      this.getCurrentUserSubOrgs();
    }
  }

  getOrderTypes = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORDER_TYPE_CHOICES}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from order_types list", response);

          this.setState({
            order_types: response.data.order_type_choices,
          });
        })
        .catch((error) => {
          console.log("error from order_types list", error);
        });
    });
  };

  getCurrentUserSubOrgs = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CURRENT_USER_SUB_ORG_LIST}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from current user sub org list", response);

          this.setState(
            {
              current_user_sub_orgs: response.data.data,
              current_user_sub_org_id:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
              sub_org_id_order_search:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
            },
            () => {
              this.getOrderList();
              if (this.state.current_user_sub_org_id !== "") {
                GET_ACCESS_TOKEN().then((token) => {
                  const url = `${GET_FIRST_CHILD_SUB_ORG_LIST}?sub_org=${this.state.current_user_sub_org_id}&limit=100&access_token=${token}`;

                  axios({
                    method: "get",
                    url: url,
                  })
                    .then((response) => {
                      console.log(
                        "response from first child sub org list",
                        response
                      );

                      this.setState({
                        first_child_sub_orgs: response.data.data,
                      });
                    })
                    .catch((error) => {
                      console.log(
                        "error from first child sub org list api",
                        error
                      );
                    });
                });
              }
            }
          );
        })
        .catch((error) => {
          console.log("error from current user sub org list api", error);
        });
    });
  };

  setCurrentUserSubOrg = (value) => {
    this.setState(
      { current_user_sub_org_id: value, sub_org_id_order_search: value },
      () => {
        this.getOrderList();
        GET_ACCESS_TOKEN().then((token) => {
          const url = `${GET_FIRST_CHILD_SUB_ORG_LIST}?sub_org=${this.state.current_user_sub_org_id}&limit=100&access_token=${token}`;

          axios({
            method: "get",
            url: url,
          })
            .then((response) => {
              console.log("response from first child sub org list", response);

              this.setState({
                first_child_sub_orgs: response.data.data,
              });
            })
            .catch((error) => {
              console.log("error from first child sub org list api", error);
            });
        });
      }
    );
  };
  setFirstChildSubOrg = (value) => {
    this.setState(
      { first_child_sub_org_id: value, sub_org_id_order_search: value },
      () => {
        this.getOrderList();
      }
    );
  };

  getOrderList = debounce(() => {
    let reduxData = {
      order_number: this.state.order_number,
      order_type: this.state.order_type,
      name: this.state.name,
      sub_org_parent_name: this.state.sub_org_parent_name,
      order_status: this.state.order_status,
      inventory_entity_name: this.state.inventory_entity_name,
      sub_org_name: this.state.sub_org_name,
      created_by_name: this.state.created_by_name,
      created_on: this.state.created_on,
      modified_by_name: this.state.modified_by_name,
      modified_on: this.state.modified_on,
      details: this.state.details,
      drop_contact_no: this.state.drop_contact_no,
      drop_contact_name: this.state.drop_contact_name,
      start_loc_name: this.state.start_loc_name,
      bill_amount: this.state.bill_amount,
      received_amount: this.state.received_amount,
      due_amount: this.state.due_amount,
      refundable_amount: this.state.refundable_amount,
      total_received_amount: this.state.total_received_amount,
      total_refunded_amount: this.state.total_refunded_amount,
      start_max_distance: this.state.start_max_distance,
      end_max_distance: this.state.end_max_distance,
      end_loc_name: this.state.end_loc_name,
      start_time: this.state.start_time,
      start_time_view: this.state.start_time_view,
      end_time: this.state.end_time,
      end_time_view: this.state.end_time_view,
      delivered: this.state.delivered,
      cancelled: this.state.cancelled,
      rescheduled: this.state.rescheduled,
      search_date_time_type: this.state.search_date_time_type,
      sort_by: this.state.sort_by,
      sort_type: this.state.sort_type,
      limit: this.state.limit,
      page: this.state.page,
    };
    this.props.saveOrderListParams(reduxData);
    console.log("reduxdata going in", reduxData);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${ADVANCED_ORDER_SEARCH}?order_type=${this.state.order_type}&page=${this.state.page}&limit=${this.state.limit}&sort_type=${this.state.sort_type}&sort_by=${this.state.sort_by}&order_status=${this.state.order_status}&sub_org_parent_name=${this.state.sub_org_parent_name}&inventory_entity_name=${this.state.inventory_entity_name}&sub_org_name=${this.state.sub_org_name}&sub_org=${this.state.sub_org_id_order_search}&search_date_time_type=${this.state.search_date_time_type}&rescheduled=${this.state.rescheduled}&cancelled=${this.state.cancelled}&delivered=${this.state.delivered}&created_by_name=${this.state.created_by_name}&modified_by_name=${this.state.modified_by_name}&order_number=${this.state.order_number}&due_amount=${this.state.due_amount}&received_amount=${this.state.received_amount}&bill_amount=${this.state.bill_amount}&end_loc_name=${this.state.end_loc_name}&start_loc_name=${this.state.start_loc_name}&drop_contact_name=${this.state.drop_contact_name}&drop_contact_no=${this.state.drop_contact_no}&details=${this.state.details}&name=${this.state.name}&end_time=${this.state.end_time}&start_time=${this.state.start_time}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from order list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
            datarange: response.data.show_data,
          });
        })
        .catch((error) => {
          console.log("error from order list api", error);
        });
    });
  }, 500);

  refresh = () => {
    this.setState(
      {
        order_number: "",
        order_type: "",
        name: "",
        sub_org_parent_name: "",
        order_status: "",
        inventory_entity_name: "",
        sub_org_name: "",
        created_by_name: "",
        created_on: null,
        modified_by_name: "",
        modified_on: null,
        details: "",
        drop_contact_no: "",
        drop_contact_name: "",
        start_loc_name: "",
        end_loc_name: "",
        bill_amount: "",
        received_amount: "",
        due_amount: "",
        refundable_amount: "",
        total_received_amount: "",
        total_refunded_amount: "",
        start_max_distance: "",
        end_max_distance: "",
        delivered: "",
        cancelled: "",
        rescheduled: "",
        sort_by: "created_on",
        sort_type: "dsc",
        search_date_time_type: "created_on",
        start_time: moment().startOf("day").format("x"),
        start_time_view: moment().startOf("day"),
        end_time: Date.now(),
        end_time_view: moment(),
        page: 0,
        limit: 10,
        current_user_sub_org_id: this.props.userProfileData.data_level
          .access_all_data
          ? ""
          : this.state.current_user_sub_org_id,
        sub_org_id_order_search: this.props.userProfileData.data_level
          .access_all_data
          ? ""
          : this.state.sub_org_id_order_search,
      },
      () => {
        this.getOrderList();
      }
    );
  };

  downloadExtendedReport = () => {
    this.setState({ show_excel_loading_button: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORDER_EXTENDED_REPORT}?order_type=${this.state.order_type}&search_date_time_type=${this.state.search_date_time_type}&rescheduled=${this.state.rescheduled}&cancelled=${this.state.cancelled}&delivered=${this.state.delivered}&created_by_name=${this.state.created_by_name}&modified_by_name=${this.state.modified_by_name}&sub_org_name=${this.state.sub_org_name}&order_status=${this.state.order_status}&sub_org_parent_name=${this.state.sub_org_parent_name}&inventory_entity_name=${this.state.inventory_entity_name}&order_number=${this.state.order_number}&due_amount=${this.state.due_amount}&received_amount=${this.state.received_amount}&bill_amount=${this.state.bill_amount}&end_loc_name=${this.state.end_loc_name}&start_loc_name=${this.state.start_loc_name}&drop_contact_name=${this.state.drop_contact_name}&drop_contact_no=${this.state.drop_contact_no}&details=${this.state.details}&name=${this.state.name}&sub_org=${this.state.sub_org_id_order_search}&start_date=${this.state.start_time}&end_date=${this.state.end_time}&access_token=${token}`;
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => res.blob())
        .then((blob) => {
          console.log("response from order excel report extended fmt", blob);
          this.setState({ show_excel_loading_button: false });
          FileSaver.saveAs(
            blob,
            `conveyance_extended_order_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.xlsx`
          );
        })
        .catch((error) => {
          this.setState({ show_excel_loading_button: false });
          console.log("error from order excel report extended api", error);
        });
    });
  };
  // downloadOrderFinanceReport = () => {
  //   GET_ACCESS_TOKEN().then((token) => {
  //     const url = `${ORDER_FINANCE_REPORT}?org_id=${
  //       GET_USER_LOCAL().organization.$oid
  //     }&from=${this.state.order_finance_report_from}&to=${
  //       this.state.order_finance_report_to
  //     }&access_token=${token}`;
  //     fetch(url, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/x-www-form-urlencoded",
  //       },
  //     })
  //       .then((res) => res.blob())
  //       .then((blob) => {
  //         console.log("response from order finacne excel report", blob);
  //         FileSaver.saveAs(
  //           blob,
  //           `conveyance_order_finance_report_${moment().format(
  //             "DD-MMMM-YYYY_hh:mm A"
  //           )}.xlsx`
  //         );
  //       })
  //       .catch((error) => {
  //         console.log("error from order finacne excel report", error);
  //       });
  //   });
  // };
  // viewOrderFinanceReport = () => {
  //   GET_ACCESS_TOKEN().then((token) => {
  //     const url = `${ORDER_FINANCE_REPORT}?org_id=${
  //       GET_USER_LOCAL().organization.$oid
  //     }&from=${this.state.order_finance_report_from}&to=${
  //       this.state.order_finance_report_to
  //     }&fmt=json&access_token=${token}`;
  //     axios({
  //       method: "get",
  //       url: url,
  //     })
  //       .then((response) => {
  //         console.log("response from order finance report list", response);

  //       })
  //       .catch((error) => {
  //         console.log("error from order finance report list", error);
  //       });
  //   });
  // };

  downloadProductSummaryReport = () => {
    this.setState({ show_excel_loading_button: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORDER_EXTENDED_MULTISHEET_EXCEL}?order_type=${this.state.order_type}&search_date_time_type=${this.state.search_date_time_type}&rescheduled=${this.state.rescheduled}&cancelled=${this.state.cancelled}&delivered=${this.state.delivered}&created_by_name=${this.state.created_by_name}&modified_by_name=${this.state.modified_by_name}&order_status=${this.state.order_status}&sub_org_parent_name=${this.state.sub_org_parent_name}&sub_org_name=${this.state.sub_org_name}&inventory_entity_name=${this.state.inventory_entity_name}&order_number=${this.state.order_number}&due_amount=${this.state.due_amount}&received_amount=${this.state.received_amount}&bill_amount=${this.state.bill_amount}&end_loc_name=${this.state.end_loc_name}&start_loc_name=${this.state.start_loc_name}&drop_contact_name=${this.state.drop_contact_name}&drop_contact_no=${this.state.drop_contact_no}&details=${this.state.details}&name=${this.state.name}&sub_org=${this.state.sub_org_id_order_search}&start_date=${this.state.start_time}&end_date=${this.state.end_time}&access_token=${token}`;
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => res.blob())
        .then((blob) => {
          console.log(
            "response from order product summary excel report fmt",
            blob
          );
          this.setState({ show_excel_loading_button: false });
          FileSaver.saveAs(
            blob,
            `conveyance_order_product_summary_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.xlsx`
          );
        })
        .catch((error) => {
          this.setState({ show_excel_loading_button: false });
          console.log("error from order excel report extended api", error);
        });
    });
  };
  downloadProductSummaryReportReturnOrder = () => {
    this.setState({ show_excel_loading_button: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${RETURN_ORDER_EXTENDED_MULTISHEET_EXCEL}?order_type=${this.state.order_type}&search_date_time_type=${this.state.search_date_time_type}&rescheduled=${this.state.rescheduled}&cancelled=${this.state.cancelled}&delivered=${this.state.delivered}&created_by_name=${this.state.created_by_name}&modified_by_name=${this.state.modified_by_name}&order_status=${this.state.order_status}&sub_org_parent_name=${this.state.sub_org_parent_name}&sub_org_name=${this.state.sub_org_name}&inventory_entity_name=${this.state.inventory_entity_name}&order_number=${this.state.order_number}&due_amount=${this.state.due_amount}&received_amount=${this.state.received_amount}&bill_amount=${this.state.bill_amount}&end_loc_name=${this.state.end_loc_name}&start_loc_name=${this.state.start_loc_name}&drop_contact_name=${this.state.drop_contact_name}&drop_contact_no=${this.state.drop_contact_no}&details=${this.state.details}&name=${this.state.name}&sub_org=${this.state.sub_org_id_order_search}&start_date=${this.state.start_time}&end_date=${this.state.end_time}&access_token=${token}`;
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => res.blob())
        .then((blob) => {
          console.log(
            "response from return order product summary excel report fmt",
            blob
          );
          this.setState({ show_excel_loading_button: false });
          FileSaver.saveAs(
            blob,
            `conveyance_return_order_product_summary_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.xlsx`
          );
        })
        .catch((error) => {
          this.setState({ show_excel_loading_button: false });
          console.log("error from return order excel report extended api", error);
        });
    });
  };

  downloadSummaryReport = () => {
    this.setState({ show_excel_loading_button: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORDER_SUMMARY_REPORT}?order_type=${this.state.order_type}&search_date_time_type=${this.state.search_date_time_type}&sub_org_name=${this.state.sub_org_name}&rescheduled=${this.state.rescheduled}&cancelled=${this.state.cancelled}&delivered=${this.state.delivered}&created_by_name=${this.state.created_by_name}&modified_by_name=${this.state.modified_by_name}&inventory_entity_name=${this.state.inventory_entity_name}&order_status=${this.state.order_status}&sub_org_parent_name=${this.state.sub_org_parent_name}&order_number=${this.state.order_number}&due_amount=${this.state.due_amount}&received_amount=${this.state.received_amount}&bill_amount=${this.state.bill_amount}&end_loc_name=${this.state.end_loc_name}&start_loc_name=${this.state.start_loc_name}&drop_contact_name=${this.state.drop_contact_name}&drop_contact_no=${this.state.drop_contact_no}&details=${this.state.details}&name=${this.state.name}&sub_org=${this.state.sub_org_id_order_search}&start_date=${this.state.start_time}&end_date=${this.state.end_time}&access_token=${token}`;
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => res.blob())
        .then((blob) => {
          console.log("response from order excel report summary fmt", blob);

          this.setState({ show_excel_loading_button: false });
          FileSaver.saveAs(
            blob,
            `conveyance_order_summary_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.xlsx`
          );
        })
        .catch((error) => {
          this.setState({ show_excel_loading_button: false });
          console.log("error from order excel report summary api", error);
        });
    });
  };

  downloadExtendedProductSummary = () => {
    this.setState({ show_pdf_loading_button: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORDER_EXTENDED_PRODUCT_SUMMARY}?order_type=${this.state.order_type}&search_date_time_type=${this.state.search_date_time_type}&sub_org_name=${this.state.sub_org_name}&rescheduled=${this.state.rescheduled}&cancelled=${this.state.cancelled}&delivered=${this.state.delivered}&created_by_name=${this.state.created_by_name}&modified_by_name=${this.state.modified_by_name}&order_status=${this.state.order_status}&sub_org_parent_name=${this.state.sub_org_parent_name}&inventory_entity_name=${this.state.inventory_entity_name}&order_number=${this.state.order_number}&due_amount=${this.state.due_amount}&received_amount=${this.state.received_amount}&bill_amount=${this.state.bill_amount}&end_loc_name=${this.state.end_loc_name}&start_loc_name=${this.state.start_loc_name}&drop_contact_name=${this.state.drop_contact_name}&drop_contact_no=${this.state.drop_contact_no}&details=${this.state.details}&name=${this.state.name}&sub_org=${this.state.sub_org_id_order_search}&start_date=${this.state.start_time}&end_date=${this.state.end_time}&access_token=${token}`;
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => res.blob())
        .then((blob) => {
          console.log("response from order product summary pdf fmt", blob);
          this.setState({ show_pdf_loading_button: false });
          FileSaver.saveAs(
            blob,
            `conveyance_order_product_summary_pdf_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.pdf`
          );
        })
        .catch((error) => {
          this.setState({ show_pdf_loading_button: false });
          console.log("error from order product summary pdf api", error);
        });
    });
  };
  downloadExtendedReportPdf = () => {
    this.setState({ show_pdf_loading_button: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORDER_EXTENDED_SUMMARY_PDF}?order_type=${this.state.order_type}&search_date_time_type=${this.state.search_date_time_type}&sub_org_name=${this.state.sub_org_name}&rescheduled=${this.state.rescheduled}&cancelled=${this.state.cancelled}&delivered=${this.state.delivered}&created_by_name=${this.state.created_by_name}&modified_by_name=${this.state.modified_by_name}&order_status=${this.state.order_status}&sub_org_parent_name=${this.state.sub_org_parent_name}&inventory_entity_name=${this.state.inventory_entity_name}&order_number=${this.state.order_number}&due_amount=${this.state.due_amount}&received_amount=${this.state.received_amount}&bill_amount=${this.state.bill_amount}&end_loc_name=${this.state.end_loc_name}&start_loc_name=${this.state.start_loc_name}&drop_contact_name=${this.state.drop_contact_name}&drop_contact_no=${this.state.drop_contact_no}&details=${this.state.details}&name=${this.state.name}&sub_org=${this.state.sub_org_id_order_search}&summary_type=${this.state.summary_type}&start_date=${this.state.start_time}&end_date=${this.state.end_time}&access_token=${token}`;
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => res.blob())
        .then((blob) => {
          console.log("response from order extended report pdf fmt", blob);
          this.setState({ show_pdf_loading_button: false });
          if (this.state.summary_type === "order") {
            FileSaver.saveAs(
              blob,
              `conveyance_order_summary_pdf_${moment().format(
                "DD-MMMM-YYYY_hh:mm A"
              )}.pdf`
            );
          }

          if (this.state.summary_type === "product") {
            FileSaver.saveAs(
              blob,
              `conveyance_extended_order_summary_pdf_${moment().format(
                "DD-MMMM-YYYY_hh:mm A"
              )}.pdf`
            );
          }
        })
        .catch((error) => {
          this.setState({ show_pdf_loading_button: false });
          console.log("error from order list pdf api", error);
        });
    });
  };
  downloadDetailedInvoice = (order_number) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORDER_DETAILED_INVOICE}?order_number=${order_number}&access_token=${token}`;

      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          console.log("response from order detailed invoice fmt", blob);

          FileSaver.saveAs(
            blob,
            `conveyance_detailed_order_invoice_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.pdf`
          );
        })
        .catch((error) => {
          console.log("error from order detailed invoice api", error);
        });
    });
  };
  downloadPosInvoice = (order_number) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORDER_INVOICE_POS}?order_number=${order_number}&access_token=${token}`;

      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          console.log("response from order pos invoice fmt", blob);

          FileSaver.saveAs(
            blob,
            `conveyance_order_pos_invoice_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.pdf`
          );
        })
        .catch((error) => {
          console.log("error from order pos invoice api", error);
        });
    });
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getOrderList();
    });
  };

  addTo = (record) => {
    let addedArray = [];
    addedArray.push(record._id.$oid);
    this.setState(
      {
        order_ids: [...this.state.order_ids, ...addedArray],
      },
      () => {
        console.log("added data", this.state.order_ids);
      }
    );
  };

  removefrom = (record) => {
    let addedFiltered = this.state.order_ids.filter(
      (m) => m !== record._id.$oid
    );
    this.setState({ order_ids: addedFiltered }, () => {
      console.log("added data", this.state.order_ids);
    });
  };

  printOrderInvoice = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORDER_INVOICE_PDF}?access_token=${token}`;
      const data = {
        // from: Number(this.state.from),
        // to: Number(this.state.to),
        order_ids: this.state.order_ids,
      };
      console.log(data);
      fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
      })
        .then((res) => res.blob())
        .then((blob) => {
          console.log("response from order invoice pdf report", blob);
          FileSaver.saveAs(
            blob,
            `conveyance_order_invoice_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.pdf`
          );
          this.getOrderList();
          this.setState({ order_ids: [] });
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log(
            "response from conveyance order invoice pdf report",
            error
          );
        });
    });
  };

  deleteTask = (id) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${EDIT_ORDER}${id}?access_token=${token}`;

      axios({
        method: "delete",
        url: url,
      })
        .then((response) => {
          swal.fire("Success", response.data.message, "success");
          setTimeout(() => window.location.reload(), 1000);
          console.log("deleted", response);
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("error from del", error);
        });
    });
  };
  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getOrderList();
    });
  };

  render() {
    return (
      <Card style={{ margin: 10, minWidth: 1000 }}>
        <Title style={{ marginLeft: 10 }} level={3}>
          Order List
        </Title>
        <Card style={{ margin: 3 }}>
          <Title level={4}>
            Select date range within which you would like to find your orders
          </Title>

          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.start_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        start_time: moment(value).format("x"),
                        start_time_view: value,
                        page: 0,
                      },
                      () => {
                        this.getOrderList();
                      }
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.end_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        end_time: moment(value).add(59, "seconds").format("x"),
                        end_time_view: value,
                        page: 0,
                      },
                      () => {
                        this.getOrderList();
                      }
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 15,
              }}
            >
              <Text style={{ margin: 5 }}>Filter Time By : </Text>
              <Select
                value={this.state.search_date_time_type}
                onChange={(value) => {
                  this.setState({ search_date_time_type: value }, () => {
                    this.getOrderList();
                    console.log(this.state.search_date_time_type);
                  });
                }}
                style={{ minWidth: 100 }}
              >
                <Option key="created_on">Created On</Option>
                <Option key="delivery_date_time">Delivery date time</Option>
                <Option key="modified_on">Modified On</Option>
                <Option key="placed_on">Placed on</Option>
              </Select>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 15,
              }}
            >
              <Text style={{ margin: 5 }}>Summary Type : </Text>
              <Select
                value={this.state.summary_type}
                onChange={(value) => {
                  this.setState({ summary_type: value }, () => {
                    this.getOrderList();
                    console.log(this.state.summary_type);
                  });
                }}
                style={{ minWidth: 100 }}
              >
                <Option key="order">Order Summary</Option>
                <Option key="product">Order Details</Option>
                <Option key="product_summary">Order Product Summary</Option>
                <Option key="return_order_product_summary">Return Order Product Summary</Option>
              </Select>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <Button
                loading={this.state.show_excel_loading_button}
                style={{
                  backgroundColor: "#1A3337",
                  color: "white",
                  marginTop: 9,
                }}
                onClick={() => {
                  if (this.state.summary_type === "order") {
                    this.downloadSummaryReport();
                  } else if (this.state.summary_type === "product") {
                    this.downloadExtendedReport();
                  } else if (this.state.summary_type === "product_summary") {
                    this.downloadProductSummaryReport();
                  } else if(this.state.summary_type === "return_order_product_summary"){
                    this.downloadProductSummaryReportReturnOrder();
                  }
                }}
              >
                {this.state.show_excel_loading_button
                  ? "Please Wait"
                  : "Download Excel"}
              </Button>
              <Button
                loading={this.state.show_pdf_loading_button}
                style={{
                  backgroundColor: "#1A3337",
                  color: "white",
                  marginTop: 9,
                }}
                onClick={() => {
                  if (this.state.summary_type === "product_summary") {
                    this.downloadExtendedProductSummary();
                  } else {
                    this.downloadExtendedReportPdf();
                  }
                }}
              >
                {this.state.show_pdf_loading_button
                  ? "Please Wait"
                  : "Download PDF"}
              </Button>
            </div>
          </div>
        </Card>

        {/* <Card style={{ margin: 3 }}>
          <Title level={4}>Order Finance Report</Title>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: 5,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  views={['year', 'month']}
                  disableFuture={true}
                  inputVariant="outlined"
                  value={this.state.order_finance_report_from_view}
                  onChange={(value, dateString) => {
                    this.setState({
                      order_finance_report_from: moment(value).startOf("month").startOf("day").format("x"),
                      order_finance_report_from_view: value,
                    });
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  views={['year', 'month']}
                  disableFuture={true}
                  inputVariant="outlined"
                  value={this.state.order_finance_report_to_view}
                  onChange={(value, dateString) => {
                    this.setState({
                      order_finance_report_to: moment(value).endOf("month").endOf("day").format("x"),
                      order_finance_report_to_view: value,
                    });
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 15,
              }}
            >
              <Button
                style={{
                  backgroundColor: "#1A3337",
                  color: "white"
                }}
                onClick={() => {
                  this.downloadOrderFinanceReport();
                }}
              >
                Download Report
              </Button>
              <Button
                style={{
                  backgroundColor: "#1A3337",
                  color: "white"
                }}
                onClick={() => {
                  this.viewOrderFinanceReport();
                }}
              >
                View Data
              </Button>
            </div>
          </div>
        </Card> */}
        {this.props.userProfileData.data_level.access_suborg_data ||
        this.props.userProfileData.data_level.access_all_data ? (
          <Card style={{ margin: 3 }}>
            <Title level={4}>Filter Orders With Sub Organization</Title>

            <div
              style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
            >
              <div style={{ margin: 10 }}>
                <Text> User Sub Organization : {""}</Text>
                <Select
                  style={{ minWidth: 200 }}
                  disabled={
                    this.state.current_user_sub_orgs.length < 1 ? true : false
                  }
                  value={this.state.current_user_sub_org_id}
                  onChange={this.setCurrentUserSubOrg}
                >
                  {this.state.current_user_sub_orgs.map((r) => {
                    return <Option key={r.id}>{`${r.name}`}</Option>;
                  })}
                </Select>
              </div>
              <div style={{ margin: 10 }}>
                <Text> Child Sub Organization : {""}</Text>
                <Select
                  style={{ minWidth: 200 }}
                  disabled={
                    this.state.first_child_sub_orgs.length < 1 ? true : false
                  }
                  value={this.state.first_child_sub_org_id}
                  onChange={this.setFirstChildSubOrg}
                >
                  {this.state.first_child_sub_orgs.map((r) => {
                    return <Option key={r.id}>{`${r.name}`}</Option>;
                  })}
                </Select>
              </div>
            </div>
          </Card>
        ) : null}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ margin: 10 }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            <Text>{`  Entries `}</Text>
          </div>
          <div style={{ marginTop: 10, textAlign: "center" }}>
            <Divider type="vertical" />
          </div>
          <div style={{ margin: 10 }}>
            <Text> Select Order Type : {""}</Text>
            <Select
              style={{ minWidth: 200 }}
              value={this.state.order_type}
              onChange={(value) =>
                this.setState({ order_type: value }, () => this.getOrderList())
              }
            >
              {this.state.order_types.map((r) => {
                return <Option key={r}>{`${r}`}</Option>;
              })}
            </Select>
          </div>
          <div style={{ marginTop: 10, textAlign: "center" }}>
            <Divider type="vertical" />
          </div>
          <div style={{ margin: 10, textAlign: "center" }}>
            <Button
              style={{ backgroundColor: "#1A3337", color: "white" }}
              onClick={this.refresh}
            >
              Refresh
            </Button>
          </div>
          <div style={{ margin: 10, textAlign: "center" }}>
            {this.state.order_ids.length > 0 ? (
              <Button
                style={{
                  color: "white",
                  backgroundColor: "#1A3337",
                }}
                htmlType="submit"
                onClick={this.printOrderInvoice}
              >
                Print Order Invoice
              </Button>
            ) : null}
          </div>
        </div>
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column
            title={
              <>
                {/* <div>Select All</div> */}
                <button
                  style={{
                    height: 15,
                    width: 14,
                    border: "1px solid grey",
                    backgroundColor:
                      this.state.order_ids.length > 0 ? "#1E88E5" : "white",
                  }}
                  onClick={() => {
                    if (this.state.order_ids.length > 0) {
                      this.setState({ order_ids: [] }, () => {
                        console.log("all order ids", this.state.order_ids);
                      });
                    } else {
                      this.setState(
                        {
                          order_ids: this.state.data.map(
                            (o_id) => o_id._id.$oid
                          ),
                        },
                        () => {
                          console.log("all order ids", this.state.order_ids);
                        }
                      );
                    }
                  }}
                ></button>
              </>
            }
            key="item_id"
            render={(text, record) => (
              <div>
                <button
                  // disabled={record.bill_report_no === "" ? false : true}
                  style={{
                    height: 15,
                    width: 14,
                    border: "1px solid grey",
                    backgroundColor: this.state.order_ids.some(
                      (d) => d === record._id.$oid
                    )
                      ? "#1E88E5"
                      : "white",
                  }}
                  onClick={() => {
                    if (
                      this.state.order_ids.some((d) => d === record._id.$oid)
                    ) {
                      this.removefrom(record);
                    } else {
                      this.addTo(record);
                    }
                  }}
                ></button>
              </div>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Order Number</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "order_number", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "order_number", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.order_number}
                  onChange={(evt) => {
                    this.setState(
                      { order_number: evt.target.value, page: 0 },
                      () => {
                        this.getOrderList();
                      }
                    );
                  }}
                />
              </>
            }
            key="order_number"
            render={(text, record) => (
              <span>{`${record?.order_number ?? ""}`}</span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Order Name</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "name", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "name", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.name}
                  onChange={(evt) => {
                    this.setState({ name: evt.target.value, page: 0 }, () => {
                      this.getOrderList();
                    });
                  }}
                />
              </>
            }
            key="name"
            render={(text, record) => <span>{`${record?.name ?? ""}`}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Order Type</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="order_type"
            render={(text, record) => (
              <span>{`${record?.order_type ?? ""}`}</span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Created By</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "created_by_name", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "created_by_name", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.created_by_name}
                  onChange={(evt) => {
                    this.setState(
                      { created_by_name: evt.target.value, page: 0 },
                      () => {
                        this.getOrderList();
                      }
                    );
                  }}
                />
              </>
            }
            key="created_by_name"
            render={(text, record) => <span>{record.created_by_name}</span>}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Created On</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "created_on", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "created_on", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <Input disabled />
              </>
            }
            key="created_on.$date"
            render={(text, record) => (
              <span>
                {record.created_on.$date
                  ? timeConverter(record.created_on.$date)
                  : ""}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <div>Details</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "details", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "details", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.details}
                  onChange={(evt) => {
                    this.setState(
                      { details: evt.target.value, page: 0 },
                      () => {
                        this.getOrderList();
                      }
                    );
                  }}
                />
              </>
            }
            render={(record) => (
              <span>
                {record.details && record.details.length > 30 ? (
                  <Text>
                    {record.details.slice(0, 10)}
                    <Button
                      type="link"
                      onClick={() =>
                        this.setState({
                          see_more_details: true,
                          details_text: record.details,
                        })
                      }
                    >
                      See More
                    </Button>
                  </Text>
                ) : (
                  <Text>{record.details}</Text>
                )}
              </span>
            )}
            key="description"
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Drop Contact Name</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "drop_contact_name", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "drop_contact_name", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.drop_contact_name}
                  onChange={(evt) => {
                    this.setState(
                      { drop_contact_name: evt.target.value, page: 0 },
                      () => {
                        this.getOrderList();
                      }
                    );
                  }}
                />
              </>
            }
            render={(text, record) => (
              <span>{record?.drop_contact_name ?? ""}</span>
            )}
            key="drop_contact_name"
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Drop Contact Number</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "drop_contact_no", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "drop_contact_no", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.drop_contact_no}
                  onChange={(evt) => {
                    this.setState(
                      { drop_contact_no: evt.target.value, page: 0 },
                      () => {
                        this.getOrderList();
                      }
                    );
                  }}
                />
              </>
            }
            key="drop_contact_no"
            render={(text, record) => (
              <span>{record?.drop_contact_no ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Delivery Address</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "end_loc_name", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "end_loc_name", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.end_loc_name}
                  onChange={(evt) => {
                    this.setState(
                      { end_loc_name: evt.target.value, page: 0 },
                      () => {
                        this.getOrderList();
                      }
                    );
                  }}
                />
              </>
            }
            dataIndex="end_loc_name"
            key="end_loc_name"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Delivery Place Name</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="end_entity"
            render={(text, record) => (
              <span>{record?.end_entity?.name ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Delivery Date Time</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "delivery_date_time", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "delivery_date_time", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <Input disabled />
              </>
            }
            key="delivery_date_time"
            render={(text, record) => (
              <span>
                {record?.delivery_date_time?.$date
                  ? timeConverter(record?.delivery_date_time?.$date)
                  : ""}
              </span>
            )}
          />

          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Pickup Location</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "start_loc_name", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "start_loc_name", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.start_loc_name}
                  onChange={(evt) => {
                    this.setState(
                      { start_loc_name: evt.target.value, page: 0 },
                      () => {
                        this.getOrderList();
                      }
                    );
                  }}
                />
              </>
            }
            render={(text, record) => (
              <span>{record?.start_entity?.address ?? ""}</span>
            )}
            key="start_loc_name"
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Inventory Entity Name</Text>
                </div>

                <Input
                  value={this.state.inventory_entity_name}
                  onChange={(evt) => {
                    this.setState(
                      { inventory_entity_name: evt.target.value, page: 0 },
                      () => {
                        this.getOrderList();
                      }
                    );
                  }}
                />
              </>
            }
            render={(text, record) => (
              <span>{record?.inventory_entity_name ?? ""}</span>
            )}
            key="inventory_entity_name"
          />
          <Column
            title={
              <>
                <Text ellipsis={true}>Sub Org Name</Text>

                <Input
                  value={this.state.sub_org_name}
                  onChange={(evt) => {
                    this.setState(
                      { sub_org_name: evt.target.value, page: 0 },
                      () => {
                        this.getOrderList();
                      }
                    );
                  }}
                />
              </>
            }
            render={(text, record) => <span>{record.sub_org?.name ?? ""}</span>}
            key="sub_org_name"
          />
          <Column
            title={
              <>
                <Text ellipsis={true}>Sub Org Parent Name</Text>

                <Input
                  value={this.state.sub_org_parent_name}
                  onChange={(evt) => {
                    this.setState(
                      { sub_org_parent_name: evt.target.value, page: 0 },
                      () => {
                        this.getOrderList();
                      }
                    );
                  }}
                />
              </>
            }
            render={(text, record) => (
              <span>{record.sub_org?.parent_sub_org_data?.name ?? ""}</span>
            )}
            key="sub_org_parent_name"
          />

          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Sub Org Type</Text>
                  <Input disabled />
                </div>
              </>
            }
            render={(text, record) => (
              <span>{record.sub_org?.sub_org_type_data?.name ?? ""}</span>
            )}
            key="sub_org_type"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Order Status</Text>

                  <Input
                    value={this.state.order_status}
                    onChange={(evt) => {
                      this.setState(
                        { order_status: evt.target.value, page: 0 },
                        () => {
                          this.getOrderList();
                        }
                      );
                    }}
                  />
                </div>
              </>
            }
            render={(text, record) => (
              <span>{record.order_status?.name ?? ""}</span>
            )}
            key="order_status"
          />
          {/* 
          <Column
            title={
              <>
                <div style={{display: "flex",flexDirection: "row",marginLeft: 2}}>
                  <div>Delivered</div>
                  <div>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "delivered", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "delivered", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.delivered}
                  onChange={evt => {
                    this.setState(
                      { delivered: evt.target.value, page: 0 },
                      () => {
                        setTimeout(() => this.getOrderList(), 2000);
                      }
                    );
                  }}
                />
              </>
            }
            render={(text, record) => (
              <span>
                {record.delivered.toString()}
              </span>
            )}
            key="delivered"
          /> */}
          {/* <Column
            title={
              <>
                <div style={{display: "flex",flexDirection: "row",marginLeft: 2}}>
                  <div>Cancelled</div>
                  <div>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "cancelled", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "cancelled", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.cancelled}
                  onChange={evt => {
                    this.setState(
                      { cancelled: evt.target.value, page: 0 },
                      () => {
                        setTimeout(() => this.getOrderList(), 2000);
                      }
                    );
                  }}
                />
              </>
            }
            render={(text, record) => (
              <span>
                {record.cancelled.toString()}
              </span>
            )}
            key="cancelled"
          /> */}
          {/* <Column
            title={
              <>
                <div style={{display: "flex",flexDirection: "row",marginLeft: 2}}>
                  <div>Rescheduled</div>
                  <div>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "rescheduled", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "rescheduled", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.rescheduled}
                  onChange={evt => {
                    this.setState(
                      { rescheduled: evt.target.value, page: 0 },
                      () => {
                        setTimeout(() => this.getOrderList(), 2000);
                      }
                    );
                  }}
                />
              </>
            }
            render={(text, record) => (
              <span>
                {record.rescheduled.toString()}
              </span>
            )}
            key="rescheduled"
          /> */}

          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Bill Amount</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "bill_amount", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "bill_amount", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <Input disabled />
                {/* <Input
                  value={this.state.bill_amount}
                  onChange={evt => {
                    this.setState(
                      { bill_amount: evt.target.value, page: 0 },
                      () => {
                        setTimeout(() => this.getOrderList(), 2000);
                      }
                    );
                  }}
                /> */}
              </>
            }
            key="bill_amount"
            render={(text, record) => (
              <span>{`${record?.bill_amount ?? ""}`}</span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Total Received Amount</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          {
                            sort_by: "total_received_amount",
                            sort_type: "asc",
                          },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          {
                            sort_by: "total_received_amount",
                            sort_type: "dsc",
                          },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <Input disabled />
              </>
            }
            key="total_received_amount"
            render={(text, record) => (
              <span>{`${record?.total_received_amount ?? ""}`}</span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Refundable Amount</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "refundable_amount", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "refundable_amount", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <Input disabled />
              </>
            }
            key="refundable_amount"
            render={(text, record) => (
              <span>{`${record?.refundable_amount ?? ""}`}</span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Total Refunded Amount</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          {
                            sort_by: "total_refunded_amount",
                            sort_type: "asc",
                          },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          {
                            sort_by: "total_refunded_amount",
                            sort_type: "dsc",
                          },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <Input disabled />
              </>
            }
            key="total_refunded_amount"
            render={(text, record) => (
              <span>{`${record?.total_refunded_amount ?? ""}`}</span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Due Amount</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "due_amount", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "due_amount", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <Input disabled />
                {/* <Input
                  value={this.state.due_amount}
                  onChange={evt => {
                    this.setState(
                      { due_amount: evt.target.value, page: 0 },
                      () => {
                        setTimeout(() => this.getOrderList(), 2000);
                      }
                    );
                  }}
                /> */}
              </>
            }
            key="due_amount"
            render={(text, record) => (
              <span>{`${record?.due_amount ?? ""}`}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Paid</Text>
                  <Input disabled />
                </div>
              </>
            }
            render={(text, record) => (
              <span>{record.paid ? record.paid.toString() : "false"}</span>
            )}
            key="paid"
          />

          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Modified By</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "modified_by_name", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "modified_by_name", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.modified_by_name}
                  onChange={(evt) => {
                    this.setState(
                      { modified_by_name: evt.target.value, page: 0 },
                      () => {
                        this.getOrderList();
                      }
                    );
                  }}
                />
              </>
            }
            key="modified_by_name"
            render={(text, record) => (
              <span>{record.modified_by_name ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Modified On</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "modified_on", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "modified_on", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <Input disabled/>
              </>
            }
            key="modified_on.$date"
            render={(text, record) => (
              <span>
                {record.modified_on.$date
                  ? timeConverter(record.modified_on.$date)
                  : ""}
              </span>
            )}
          />

          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                <Button
                  type="link"
                  style={{ color: "green" }}
                  onClick={() =>
                    this.downloadDetailedInvoice(record.order_number)
                  }
                >
                  Download Detailed Invoice
                </Button>
                <Button
                  type="link"
                  style={{ color: "green" }}
                  onClick={() => this.downloadPosInvoice(record.order_number)}
                >
                  Download POS Invoice
                </Button>
                {this.props.userProfileData.order_and_delivery.order
                  .order_add_reminder ? (
                  <Link
                    to={{
                      pathname: "/createReminder",
                      state: {
                        order_id: record._id.$oid,
                        order_name: record.name,
                      },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      Add Reminder
                    </Button>
                  </Link>
                ) : null}
                {this.props.userProfileData.order_and_delivery.order
                  .generate_task_from_order ? (
                  <Link
                    to={{
                      pathname: "/genTask",
                      state: { order_id: record._id.$oid },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      Generate Task
                    </Button>
                  </Link>
                ) : null}

                <Link
                  to={{
                    pathname: "/viewOrder",
                    state: { order_id: record._id.$oid },
                  }}
                >
                  <Button type="link" style={{ color: "green" }}>
                    View
                  </Button>
                </Link>
                {/* {this.props.userProfileData.order_and_delivery.order
                  .order_update ? ( */}
                <Link
                  to={{
                    pathname: "/editOrder",
                    state: { order_id: record._id.$oid },
                  }}
                >
                  <Button type="link" style={{ color: "green" }}>
                    Edit
                  </Button>
                </Link>
                {/* ) : null} */}

                <div>
                  {GET_USER_LOCAL().is_staff === true ||
                  GET_USER_LOCAL().org_admin ||
                  this.props.userProfileData.order_and_delivery.order
                    .order_delete ? (
                    <div>
                      <Button
                        type="link"
                        style={{ color: "red" }}
                        onClick={() => {
                          if (window.confirm("Delete the item?")) {
                            this.deleteTask(record._id.$oid);
                          }
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  ) : null}
                </div>
              </span>
            )}
          />
        </Table>
        <div style={{ marginRight: 10, marginTop: 15 }}>
          <Text>{`Displaying ${this.state.datarange} of ${this.state.total}`}</Text>
        </div>
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
        <Modal
          destroyOnClose={true}
          footer={null}
          visible={this.state.see_more_details}
          onCancel={() => {
            this.setState({
              see_more_details: false,
              details_text: "",
            });
          }}
          closable={true}
        >
          <Text style={{ margin: 10 }}>{this.state.details_text}</Text>
        </Modal>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  orderListParams: state.orderListParams.orderListParamsData,
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { saveOrderListParams, getUserData })(
  AdvancedOrderList
);
